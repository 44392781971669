<template>
	<div class="page_content">
		<div class="page_detail">
			<a-divider orientation="left">基本信息</a-divider>
			<a-descriptions :column="2">
				<a-descriptions-item label="仓管">
					{{ detail.username||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="状态">
					{{ detail.status_name||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="仓库">
					{{ detail.storage_name }}
				</a-descriptions-item>
				<a-descriptions-item label="仓库编号">
					{{ detail.storage_id }}
				</a-descriptions-item>
				<a-descriptions-item label="承运商">
					{{ detail.merchant_name||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="承运商编号">
					{{ detail.merchant_id||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="司机">
					{{ detail.chauffeur||'--'}}
				</a-descriptions-item>
				<a-descriptions-item label="司机电话">
					{{ detail.chauffeur_mobile||'--'}}
				</a-descriptions-item>
				<a-descriptions-item label="车牌">
					{{detail.truck_license}}
				</a-descriptions-item>
				<a-descriptions-item v-if="detail.status < 6 && detail.status != -1">
					<a-button class="a_btn" type="primary" @click="change_order_status"
						v-if="detail.send_status == 1">开始拣货</a-button>
					<a-button class="a_btn" type="primary" @click="change_order_status"
						v-else-if="detail.send_status == 2">完成装车</a-button>
					<!-- <a-button class="a_btn" type="primary" @click="change_order_status"
						v-else-if="detail.send_status == 3">{{detail.is_driver_sign == -1 ? "司机确认" : "仓管确认"}}</a-button> -->
					<a-button class="a_btn" type="primary" @click="change_order_status"
						v-else-if="detail.send_status == 4">司机送达</a-button>
					<a-button class="a_btn" type="primary" @click="change_order_status" v-else>完成订单</a-button>
					<!-- <a-button class="a_btn" type="primary" @click="change_order_status">{{detail.send_status == 1 ? "开始拣货" : (detail.send_status == 2 ? "完成装车" : (detail.send_status == 3 ? (detail.is_driver_sign == -1 ? "司机确认" : "仓管确认") : (detail.send_status == 4 ? "司机送达" : "完成订单")))}}</a-button> -->
					<a-button class="a_btn" type="primary" @click="change_driver_info"
						style="margin-left:10px">修改电话车牌</a-button>
				</a-descriptions-item>
			</a-descriptions>

			<a-divider orientation="left">订单信息</a-divider>
			<div v-for="(item,index) in detail.order_info" style="margin-bottom: 20px;">
				<div>
					<a-descriptions :column="2">
						<a-descriptions-item label="经销商">
							{{ item.seller_name||'--' }}
						</a-descriptions-item>
						<a-descriptions-item label="电话">
							{{ item.phone||'--' }}
						</a-descriptions-item>
						<a-descriptions-item label="经销商编号">
							{{ item.seller_id }}
						</a-descriptions-item>
						<a-descriptions-item label="收货人">
							{{ item.consignee }}
						</a-descriptions-item>
						<a-descriptions-item label="收货地址">
							{{ item.address||'--' }}
						</a-descriptions-item>
						<a-descriptions-item label="状态">
							{{ detail.status < 4 ? '还未配送' : (item.confirm_status < 2 ? '配送中' : (item.confirm_status == 2 ? '已送达' : '已签收'))}}
							<a-tooltip v-if="item.confirm_status >= 2"
								style="margin-left:20px;color:#397dee;cursor:pointer;"
								@click="add_address(item.latitude,item.longitude,item.arrive_detail)">
								<template #title>{{ item.arrive_detail }}</template>
								{{ item.arrive_address }}
							</a-tooltip>
						</a-descriptions-item>
						<a-descriptions-item>
							<a-button v-if="detail.status == 4 || detail.status == 5" class="a_btn" type="primary"
								@click="change_outcome_status(item.sales_seller_id)">{{item.confirm_status < 2 ? "送达" : "签收"}}</a-button>
						</a-descriptions-item>
						<a-descriptions-item label="轨迹" v-if="item.driver_locus.length > 0">
							<div class="trajectory_main">
								<div class="trajectory_box" v-for="(locus,indexL) in item.driver_locus">
									<div class="itema">{{locus.status == 4 ? "配送中" : "已送达"}}</div>
									<div class="itemb">{{locus.create_time}}</div>
									<div class="itemc">
										<a-tooltip style="color:#397dee;cursor:pointer;" @click="add_address(locus.latitude,locus.longitude,locus.address)">
											<template #title>{{ locus.address }}</template>
											{{ locus.address }}
										</a-tooltip>
									</div>
								</div>
							</div>
						</a-descriptions-item>

					</a-descriptions>
				</div>
				<div v-for="(its,ind) in item.outcome_data">
					<div>
						<a-descriptions :column="2">
							<a-descriptions-item label="出库单号">
								{{ its.outcome_id||'--' }}
							</a-descriptions-item>
						</a-descriptions>
					</div>
					<div v-for="goods_data in its.goods_data">
						<a-descriptions :column="2">
							<a-descriptions-item label="产品明细">
								<a-table class="mt10" :columns="columns" :pagination="false"
									:dataSource="[goods_data.goods_data]">
								</a-table>
							</a-descriptions-item>
						</a-descriptions>
						<a-descriptions :column="2">
							<a-descriptions-item>
								<p style="font-size: 14px;color: #000;">
									<span>溯源码数量：共 {{goods_data.code_info.num}} 个</span>
									<span style='color:#1890ff;margin-left: 12px;cursor: pointer;' @click='goods_data.code_info.open=!goods_data.code_info.open;$forceUpdate();'>{{goods_data.code_info.open?'收起':'展开'}}</span>
								</p>
								<div v-if='goods_data.code_info.open'>
									<a-table class="mt10" :columns="columns_code" :pagination="false"
										:dataSource="goods_data.code_info.list">
										<template slot="num" slot-scope="record">
										    <a @click="go_list(goods_data.goods_data)">{{record}}</a>
										</template>
									</a-table>
								</div>
							</a-descriptions-item>
						</a-descriptions>
						
						<a-descriptions :column="2" v-if='goods_data.batch_info.num>0'>
							<a-descriptions-item>
								<p style="font-size: 14px;color: #000;">
									<span>批次号数量：共 {{goods_data.batch_info.num}} 个</span>
									<span style='color:#1890ff;margin-left: 12px;cursor: pointer;' @click='goods_data.batch_info.open=!goods_data.batch_info.open;$forceUpdate();'>{{goods_data.code_info.open?'收起':'展开'}}</span>
								</p>
								<div v-if='goods_data.batch_info.open'>
									<a-table class="mt10" :columns="columns_code_batch" :pagination="false"
										:dataSource="goods_data.batch_info.list">
									</a-table>
								</div>
							</a-descriptions-item>
						</a-descriptions>
					</div>
				</div>
			</div>
			<div class="box_form box_form_btns">
				<a-button type="defalut" @click="$router.back()">返回</a-button>
			</div>
		</div>
		
		<EditPop ref="edit_pop" @handleSubmit="submit_driver" :form_data="form_data" :visible.sync="visible"></EditPop>
		<a-modal v-model:visible="map_visible" title="送达位置" :footer="null" :maskClosable="false" :centered="true" :width="'800px'">
			<simple-map v-if="map_visible" :lng='longitude' :location='address' :lat='latitude' @get_title="get_title"></simple-map>
		</a-modal>
	</div>
</template>

<script>
	import Map from "@/components/EditPop";
	import SimpleMap from "../components/BaiduMap";
	
	import {
		get_send_detail,
		changeSendOrderStatus,
		changeOutcomeStatus,
		change_driver
	} from '@/api/push.js'
	import EditPop from "@/components/EditPop";
	import TengXunMap from '@/components/TengXunMap/map'
	import SelectUser from "@/components/SelectUser";
	const columns = [{
			title: "产品名称",
			dataIndex: "goods_desc",
		},
		{
			title: "购货数量",
			dataIndex: "goods_count",
		},
		{
			title: "折扣数量",
			dataIndex: "discount",
		},
		{
			title: "促销数量",
			dataIndex: "promotion_count",
		},
		{
			title: "数量总计",
			dataIndex: "chest_count",
		},
	];
	const columns_code = [{
			title: "垛码编号",
			dataIndex: "pallet_guid",
		},
		{
			title: "溯源码数量",
			dataIndex: "num",
			scopedSlots: {
			    customRender: "num"
			}
		},
	];
	
	const columns_code_batch=[{
			title: "批次号",
			dataIndex: "product_batch",
		},
		{
			title: "数量",
			dataIndex: "num",
			
		},
	];

	export default {
		components: {
			EditPop,
			TengXunMap, Map,
            SimpleMap
		},
		data() {
			return {
				map_visible: false,
				map: {},
				columns_code,
				columns_code_batch,
				columns,
				detail: {},
				visible: false,
				form_data: {
					title: '修改司机电话/车牌',
					list: [{
							type: "text",
							name: "chauffeur_mobile",
							title: "司机电话",
							options: {}
						},
						{
							type: "text",
							name: "truck_license",
							title: "车牌",
							options: {}
						},
					]
				},
				visibleStatus: false,
				mapData: {},
				latitude:'',
				longitude:'',
				 address:''
			}
		},
		created() {
			this.get_info()
		},
		methods: {
			get_title(e) {
			   
			},
			map_submit(){},
			add_address(latitude, longitude, address) {
				this.latitude=latitude
				this.longitude=longitude
				this.address=address
			    this.map_visible = true;
			},
			go_list(goods_data) {
				this.$router.push({
					path: '/traceability/code',
					query: {
						delivery_id : this.$route.query.id,
						outcome_id :goods_data.outcome_id ,
						goods_id:goods_data.goods_id
					}
				})
			},
			get_info() {
				get_send_detail({
					data: {
						id: this.$route.query.id,
						// seller_id:this.$route.query.seller_id
						sales_seller_id: this.$route.query.sales_seller_id
					}
				}).then(res => {
					this.detail = res.data.list
					res.data.list.order_info.forEach(item=>{
						item.outcome_data.forEach(it=>{
							it.goods_data.forEach(good=>{
								if(good.code_info){
									good.code_info.open = false
								}
								if(good.batch_info){
									good.batch_info.open = false
								}
							})
						})
					})
					
					
				})
			},
			change_order_status() {
				changeSendOrderStatus({
					data: {
						id: this.$route.query.id
					},
					info: true,
				}).then(res => {
					if (res.code == 1) {
						this.get_info()
					}
				})
			},
			change_outcome_status(seller_id) {
				changeOutcomeStatus({
					data: {
						id: this.$route.query.id,
						sales_seller_id: seller_id
					},
					info: true,
				}).then(res => {
					if (res.code == 1) {
						this.get_info()
					}
				})
			},
			change_driver_info() {
				this.form_data.list.forEach(item => {
					item.options.initialValue = this.detail[item.name]
				})
				this.visible = true
			},
			submit_driver(e) {
				this.visible = false
				change_driver({
					data: {
						...e.values,
						delivery_id: this.$route.query.id
					},
					info: true,
				}).then(res => {
					if (res.code == 1) {
						this.get_info()
					}
				})
			},
			//显示腾讯地图
			getShowTengXunMap(latitude, longitude, address) {
				this.visibleStatus = true
				this.mapData = {
					lng: longitude,
					lat: latitude,
					address: address
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	.time_line {
		max-width: 800px;
	}

	.avatar_ul {
		display: flex;
		flex-wrap: wrap;

		.avatar {
			margin: 16px 10px 0;
			width: 80px;
		}
	}

	.page_detail {
		margin: 0;
		margin-left: 5%;

	}

	.li_item {
		width: 100%;

		.title_item {
			width: 80px;
			text-align: left;
			display: inline-block;
			color: #000;
		}

		margin-bottom: 30px;
	}

	.arpproval_title {
		padding: 15px 0;
		text-align: center;
		background: rgba(245, 245, 245, 1);
		font-size: 14px;

		span {
			width: 9%;
			display: inline-block;
		}

		.department_appr {
			width: 18%;
		}
	}

	.arpproval_title1 {
		height: auto;
		width: 300px;
		background: none;
		border: 1px solid rgba(245, 245, 245, 1);

		.department_appr {
			width: 50%;
		}
	}

	.arpproval_title2 {
		background: #fff;
		border: 1px solid rgba(245, 245, 245, 1);
	}

	.go_see {
		color: #1890ff;
	}

	.ant-descriptions-item-label {
		width: 100px;
	}

	.carrier_box {
		margin-top: 10px;
	}

	.carrier_info {
		margin-bottom: 5px;

		.title {
			color: rgba(0, 0, 0, 0.85);
		}

		span {
			padding-right: 15px;
		}
	}

	.trajectory_main{
		width:100%;
		max-height:105px;
		overflow: auto;
		
		.trajectory_box{
			display:flex;

			.itema{
				width:55px;
			}
			.itemb{
				width:150px;
			}
			.itemc{
				width:255px;
				white-space: nowrap;   /* 强制不换行 :ml-search[white-space] */
				overflow: hidden;      /* 隐藏溢出内容 :ml-search[overflow] */
				text-overflow: ellipsis; /* 显示省略号 :ml-search[text-overflow] */
			}
		}
	}
</style>